import { createContext, useState } from "react";


export const ContextInvitation = createContext();

export const SetInvitationProvider = props => {
    const [open, setOpen] = useState(false)

    return(
        <ContextInvitation.Provider value={[open,setOpen]}>
            {props.children}
        </ContextInvitation.Provider>
    )
}