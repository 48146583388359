import hero from '../../public/assets/hero.jpeg'
import video4 from '../../public/video/vanny n frista beach.m4v'

const Hero = () => {
    return(
        <>
            <video src={video4} className='w-full md:w-1/2 md:h-[80vh]  m-auto' autoPlay controls controlsList='nodownload' muted loop/>
            <div className="flex flex-col md:flex-row justify-around md:m-10">
                <div className="w-full md:w-1/2 self-center md:p-16">
                    <img src={hero} className='p-0 h-96 w-full md:rounded-lg md:p-0 md:h-[50vh] md:w-[50vh] object-cover' alt="hero-img"/>
                </div>
                <div className="flex flex-col justify-around w-full p-2 md:p-16 mb-5">
                    <div className='mb-16'>
                        <div className='text-2xl md:text-4xl font-bold'>Matthew 19:6</div>
                        <div className='text-xl md:text-3xl font-bold'>
                            "So they are no longer two, but one flesh. Therefore what God has joined together, let no one separate."
                        </div>
                    </div>
                    <div className='font-bold md:text-2xl'>
                        By the grace and blessing of God, We Cordially invite you to share the joy of our marriage
                    </div>
                </div>
            </div>
            <div>
                <div className="border-b-2 mx-2 md:mx-0 h-full border-black md:border-b-0"></div>
            </div>
        </>
    )
}

export default Hero