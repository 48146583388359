import { useEffect, useState } from "react"
import About from "../../components/about";
import Footer from "../../components/footer";
import Gallery from "../../components/gallery";
import Hero from "../../components/hero";
import MusicButton from "../../components/musicButton";
import NavBar from "../../components/navbar/navbar"
import RSVP from "../../components/rsvp";
import WeddingInfo from "../../components/weddingInfo";
import music from '../../public/music/Jason Castro - Let\'s Just Fall In Love Again.mp3'


const HomeInvitation = () => {
    const [fadeIn, setFadeIn] = useState(false)
    const [mute, setMute] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeIn(true);
        }, 500);
        return () => clearInterval(interval);
      }, []);

    return (
        <div className={fadeIn ? "transition-opacity duration-1000 ease-in opacity-100" : "opacity-0"}>
            <NavBar/>
            <Hero/>
            <About/>
            <Gallery/>
            <WeddingInfo/>
            <RSVP/>
            <Footer/>
            <MusicButton music={music} mute={mute} setMute={()=>setMute(!mute)} />
        </div>
    )
}

export default HomeInvitation