import { useContext } from 'react';
import './App.css';
import { ContextInvitation } from './context/openInvitation';
import InvitationCover from './pages/cover';
import HomeInvitation from './pages/home';

function App() {
  const[open, ] = useContext(ContextInvitation)

  return (
    <div className="App font-Poiret font-bold">
      {!open && <InvitationCover/>}
      {open && <HomeInvitation/>}
    </div>
  );
}

export default App;
