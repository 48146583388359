// import groom from "./../../public/assets/undraw_Businessman_re_mlee.png"
import groom from "./../../public/assets/groom.jpg"
import bride from "./../../public/assets/undraw_My_universe_803e.jpg"
import cross from "./../../public/assets/cross.png"
import instagram from "./../../public/assets/instagram.png"


const About = () => {
    return(
        <div className="my-5">
            <div>
                <div className="text-3xl font-bold mb-5 md:text-5xl">Groom</div>
                <div className="flex flex-col md:flex-row justify-around">
                    <div className="self-center">
                        <div className="text-2xl font-bold md:text-3xl md:mb-3">Frista Friciandi Kresna, S.IP</div>
                        <div className="text-lg md:text-2xl md:mb-3">The Son Of : </div>
                        <div className="text-lg md:text-2xl md:mb-3">Mr. Arif Fatah Kartiwa (<img className="inline" alt="cross1" src={cross} width={15}/>) & Mrs. Maryani</div>
                        <a target="_blank"  rel="noreferrer" href="https://www.instagram.com/kresnafriciandi/">
                            <img className="inline" alt="insta" src={instagram} width={30} />
                        </a>
                    </div>
                    <div>
                        <img className="rounded-full h-96 w-96 object-cover p-10 md:p-0" src={groom} alt="groom"/>
                    </div>
                </div>
            </div>
            <div>
                <div className="text-3xl font-bold mt-5 md:text-5xl">Bride</div>
                <div className="flex flex-col md:flex-row justify-around">
                    <div>
                        <img className="rounded-full h-96 w-96 object-cover p-10 md:p-0"  src={bride} alt="bride" />
                    </div>
                    <div className="self-center">
                        <div className="text-2xl font-bold md:text-3xl md:mb-3">Septevanny Gunawan, S.Kom</div>
                        <div className="text-lg md:text-2xl md:mb-3">The Daughter Of : </div>
                        <div className="text-lg md:text-2xl md:mb-3">Mr. Wigena Gunawan (<img className="inline" alt="cross2" src={cross} width={15}/>) & Mrs.Marike</div>
                        <a target="_blank"  rel="noreferrer" href="https://www.instagram.com/septevanny18/">
                            <img className="inline" alt="insta" src={instagram} width={30} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About