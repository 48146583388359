import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { image1, image2, image3, image4, image5, image6, image7, image8, image9, image10 , image11 , image12, image13, image14} from "../galleryImage/index"
import "swiper/css";
import "swiper/css/pagination";


const Gallery = () => {
    const [gallery,] = useState([ image1, image2, image3, image4, image5, image6, image7, image8, image9, image10 , image11 , image12, image13, image14 ])

    return(
        <>
            <div className="text-3xl font-bold mt-10 mb-5">Gallery</div>
            <Swiper
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                centeredSlides={true}
                loop={true}
                loopFillGroupWithBlank={true}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                pagination={{
                    clickable: true,
                  }}
            >
                {
                    gallery.map((val, index)=>{
                        return(
                            <SwiperSlide key={index}>
                                <img alt="gallery4" className="h-[25rem] md:h-[40rem] m-auto object-contain" src={val} />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </>
    )
}

export default Gallery