import { useContext, useState } from "react"
import { ContextInvitation } from "../../context/openInvitation";

const InvitationCover = () => {
    const[,setOpen] = useContext(ContextInvitation)
    const[transition, setTransition] = useState(false)

    const queryParams = new URLSearchParams(window.location.search);
    const name = queryParams.get('to');

    return(
        <div className={transition ? "transition-opacity duration-1000 ease-out opacity-0 border w-full h-screen" :"transition-opacity duration-1000 ease-in opacity-100 border w-full h-screen"}>
            <div className=" text-primary900 h-full bg-no-repeat bg-front bg-center bg-opacity-95 bg-cover">
                <div className="flex flex-col justify-around h-full">
                    <div>
                        <h1 className="text-4xl font-bold mb-3 text-primary300 drop-shadow-4xl md:text-5xl ">THE WEDDING OF</h1>
                        <div className="text-4xl font-bold mb-5 text-primary300 drop-shadow-4xl md:text-5xl">Frista & Vanny</div>
                        <div className="text-2xl text-primary300 drop-shadow-4xl md:text-4xl">Saturday | 05/11/2022</div>
                    </div>
                    <div>
                        {
                            name &&
                            <>
                                <div className=" text-2xl text-primary300 font-bold drop-shadow-4xl">Dear:</div>
                                <div className=" font-bold text-xl text-primary800 w-11/12 md:w-1/5 m-auto p-10 md:p-16 content-center rounded-lg bg-[#FFFFFF] bg-opacity-50 backdrop-blur-sm">
                                    {name}
                                </div>
                                <div className="text-xs text-primary300 drop-shadow-4xl mb-10">We Apologize for any misspelled of name</div>
                            </>
                        }
                        <button 
                        className="py-1.5 px-8 border bg-primary300 font-black rounded-md inline-block hover:bg-primary800 hover:text-primary300 hover:border-primary700"
                        onClick={()=>{setTransition(true);setInterval(()=>setOpen(true), 1000)}}
                        >Open Invitation</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvitationCover