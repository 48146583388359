import { useEffect, useState } from "react"
import WeddingGift from "../weddingGift"
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RSVP = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const name = queryParams.get('to');
    const type = queryParams.get('type');

    const [gift, setGift] = useState(false)
    const [testimony, setTestimony] = useState([])
    const [inputForm, setInputForm] = useState({name:name?.toLowerCase().includes('teradata') ? "" : name, attend:'', testimony:'', code:'fsv'})
    const [getTesti, setGetTesti] = useState(true)
    

    useEffect(() => {
        if(getTesti){
            getTestimony()
        }
        
    },[getTesti]);

    const getTestimony = () => {
        axios.post('https://db-everlastinglove.my.id/testimony/get', {code : "fsv"})
        .then((res)=>{
            if(res.status === 200){
                setTestimony(res.data.data)
                setGetTesti(false)
            }else{
                setGetTesti(true)
            }
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    const handleChange = (e) => {
        let name = e.target.name
        let val = e.target.value
        setInputForm({...inputForm, [name]:val})
    
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        
        axios.post('https://db-everlastinglove.my.id/testimony/post', inputForm)
        .then((res)=>{
            getTestimony()
            if(res.status === 200){
                setInputForm({name:name, attend:'', testimony:''})
                toast.success("Berhasil Submit", { position: 'top-center', autoClose: 1500 });
            }else{
                toast.error("Terjadi Kesalahan, Silahkan Coba Lagi", { position: 'top-center', autoClose: 1500 });
            }
        })
        .catch((err)=>{
            console.log(err)
            toast.error("Terjadi Kesalahan", { position: 'top-center', autoClose: 1500 });

        })
    }

    return(
        <div className="mb-5">
            <ToastContainer/>
            <div className="text-3xl font-bold mb-5">Can You Make It?</div>
            <div className="flex flex-col mb-5 md:flex-row justify-around border md:mx-36 md:p-10 md:rounded-lg bg-primary300">
                <div className="w-full p-2 md:p-10">
                    <form onSubmit={handleSubmit}>
                        <div className="flex my-5">
                            <label required className="basis-2/5 text-left" htmlFor="name">Name</label>
                            <input required disabled={name === null ? true : false} onChange={handleChange} value={inputForm.name} className="basis-4/5 w-full" type="text" id="name" name="name"/>
                        </div>
                        <div className="flex my-5">
                            <label className="basis-2/5 text-left">Will You Attend</label>
                            <select disabled={name === null ? true : false} value={inputForm.attend} onChange={handleChange} name='attend' required className="basis-4/5 w-full">
                                <option className="font-bold" value={""}>Select...</option>
                                <option className="font-bold" value={"1"}>Holy Matrimony</option>
                                {
                                    type === "0" &&
                                    <>
                                        <option className="font-bold" value={"2"}>Reception </option>
                                        <option className="font-bold" value={"3"}>Holy Matrimony & Reception</option>
                                    </>
                                }
                                <option className="font-bold" value={"4"}>Not Come</option>
                            </select>
                        </div>
                        <div className="flex my-5">
                            <label className="basis-2/5 text-left">Testimony</label>
                            <textarea disabled={name === null ? true : false} value={inputForm.testimony} onChange={handleChange} required name='testimony' className="basis-4/5 w-full h-[20vh]"/>
                        </div>
                        <div className="flex justify-center md:justify-end">
                            {
                                name !== null &&
                                <input 
                                className="cursor-pointer py-1.5 px-8 border text-primary300 bg-primary800 border-primary700 font-semibold rounded-md inline-block hover:bg-primary300 hover:text-primary900 hover:border-primary900"
                                type={"submit"}/>
                            }
                        </div>
                    </form>
                </div>
                <div>
                    <div className="border-b-2 mx-2 mt-5 md:mt-0 md:mx-0 md:border-l-2 h-full border-black"></div>
                </div>
                <div className="w-full p-2 md:p-10">
                    <div className="text-xl font-bold my-5">TESTIMONY</div>
                    <div className="testimony-content md:rounded-lg bg-[#FFFFFF] bg-opacity-90 backdrop-blur-sm p-5 overflow-auto max-h-96 min-h-[25rem]">
                        {
                            testimony.map((val, index) => {
                                return(
                                    <div key={index}>
                                        <div className="text-left p-2">{val.name} - {val.created_at.substring(0, 10).split('-').reverse().join('/')}</div>
                                        <div role='textbox' className="whitespace-pre-wrap text-left testimony-content w-full bg-primary300 p-2 rounded-sm">{val.testimony}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="mb-5">
                <div className="mb-2">
                    <div className="text-center text-xl px-5">
                        Share our moment with Instagram Filter 
                    </div>
                </div>
                <a 
                    className="py-1.5 px-8 border text-primary300 bg-primary800 border-primary700 font-semibold rounded-md inline-block hover:bg-primary300 hover:text-primary900 hover:border-primary900"                                                                                                                                
                    target="_blank"  rel="noreferrer" href="https://www.instagram.com/ar/656077372532189">
                    Instagram Filter #FristaVanny
                </a>
            </div>

            <div className="mb-2">
                <div className="text-center text-xs px-5">
                    Online blessing registry is available to our bank account. 
                </div>
                <div className="text-center text-xs px-5">
                    We'll be delighted and forever grateful to receive your love & kindness.
                </div>
            </div>
            <button 
                className="py-1.5 px-8 border text-primary300 bg-primary800 border-primary700 font-semibold rounded-md inline-block hover:bg-primary300 hover:text-primary900 hover:border-primary900"
                onClick={()=>{setGift(!gift)}}
            >Wedding Gift</button>
            {
                gift &&
                <WeddingGift/>
            }

        </div>
    )
}

export default RSVP