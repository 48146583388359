import { useState, useEffect } from 'react';

const Countdown = () => {
    // let countDownDate = new Date("Sep 6, 2022 00:00:00").getTime();
    let countDownDate = new Date("Nov 5, 2022 00:00:00").getTime();
    const [days, setDays] = useState(null);
    const [hours, setHours] = useState(null);
    const [minutes, setMinutes ] = useState(null);
    const [seconds, setSeconds ] =  useState(null);
    const [over, setOver] = useState(null);


    useEffect(()=>{
        let myInterval = setInterval(() => {
            let now = new Date().getTime();
            let distance = countDownDate - now
            if(distance < 0){
                setOver('Time To Celebrate!,Thank You :)')
                return ()=>{
                    clearInterval(myInterval);
                }
            }else{
                setDays(Math.floor(distance / (1000 * 60 * 60 * 24))); 
                setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
                setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
                setSeconds(Math.floor((distance % (1000 * 60)) / 1000));        
            }

            }, 1000)
        });

    if (days == null && hours == null && minutes == null && seconds == null){
        return(
            // <div className='text-center display-4' style={{fontWeight:'500'}}>{console.log(days)}Loading...</div>
            <div className='countdown-footer text-2xl font-bold'>{over}</div>
        )
    };

    return(
        <div className='countdown-wrapper'>
            {/* <div id='countdown' className='text-center' style={{fontWeight:'400', fontSize:'8vh'}}> {over == null ? `${days} d ${hours} h ${minutes} m ${seconds} s` : over} </div> */}
            {
                over == null ?
                <>
                    <div className='flex text-2xl justify-center'>
                        <div className='bg-primary800 text-primary300 rounded-full p-2 mr-2 w-[50px]'> 
                        {days}
                        {/* {over == null ? `${days} d ${hours} h ${minutes} m ${seconds} s` : over}  */}
                        </div>
                        <div className='mr-2 self-center'>
                            d
                        </div>
                        <div className='bg-primary800 text-primary300 rounded-full p-2 mr-2 w-[50px]'> 
                        {hours}
                        </div>
                        <div className='mr-2 self-center'>
                            h
                        </div>
                        <div className='bg-primary800 text-primary300 rounded-full p-2 mr-2 w-[50px]'> 
                        {minutes}
                        </div>
                        <div className='mr-2 self-center'>
                            m
                        </div>
                        <div className='bg-primary800 text-primary300 rounded-full p-2 mr-2 w-[50px]'> 
                        {seconds}
                        </div>
                        <div className='mr-2 self-center'>
                            s
                        </div>
                    </div>
                    <div className='countdown-footer text-xl font-bold'>Till We Celebrate It!</div>
                </>
                :
                <div className='countdown-footer text-2xl font-bold'>{over}</div>
            }
        </div>
    )
}

export default Countdown