import mask from "./../../public/healthProtocol/medical-mask.png"
import washHand from "./../../public/healthProtocol/washing-hands.png"
import socialDistancing from "./../../public/healthProtocol/distance.png"
import handshake from "./../../public/healthProtocol/no-handshake.png"
import temperature from "./../../public/healthProtocol/thermometer-gun.png"
import sanitazer from "./../../public/healthProtocol/hand-sanitizer.png"
import instagram from '../../public/assets/instagram.png'

const Footer = () => {
    return(
        <div className="bg-primary300">
            <div className="bg-footer md:bg-footer-web h-96 md:h-[500px] bg-cover bg-center bg-no-repeat opacity-90">
                <div className="text-xl p-5 md:p-0 md:text-3xl drop-shadow-4xl font-bold md:w-1/2 m-auto text-primary300 h-full md:flex md:justify-center items-center">Our joy will be complete with your presence and blessing in this celebration</div>
            </div>
            <div>
                <div className="py-8">
                    <h2 className="text-3xl font-bold">Health Protocol</h2>
                    <div className="text-sm px-2">It's important to us that everyone stays safe, please read carefully our Wedding Day Covid-19 Guidance before attending the event:</div>
                    <div className="flex flex-wrap justify-center md:w-4/5 m-auto">
                        <div className="basis-2/5 my-5 md:basis-1/5 md:mx-10 md:my-10 flex flex-col items-center">
                            <img className="w-[10VH] border border-black rounded-full" src={mask} alt="mask"/>
                            <div className="text-sm">Wear a Mask</div>
                        </div>
                        <div className="basis-2/5 my-5 md:basis-1/5 md:mx-10 md:my-10 flex flex-col items-center">
                            <img className="w-[10VH] border border-black rounded-full" src={washHand} alt="washhand"/>
                            <div className="text-sm">Wash Your Hand</div>
                        </div>
                        <div className="basis-2/5 my-5 md:basis-1/5 md:mx-10 md:my-10 flex flex-col items-center  ">
                            <img className="w-[10VH] flex items-center border border-black rounded-full" src={socialDistancing} alt="socialdistancing"/>
                            <div className="text-sm">Social Distancing</div>
                        </div>
                        <div className="basis-2/5 my-5 md:basis-1/5 md:mx-10 md:my-10 flex flex-col items-center">
                            <img className="w-[10VH] border border-black rounded-full" src={handshake} alt="handshake"/>
                            <div className="text-sm">Handshakes are prohibited</div>
                        </div>
                        <div className="basis-2/5 my-5 md:basis-1/5 md:mx-10 md:my-10 flex flex-col items-center">
                            <img className="w-[10VH] border border-black rounded-full" src={temperature} alt="temperature"/>
                            <div className="text-sm">Temperature Check</div>
                        </div>
                        <div className="basis-2/5 my-5 md:basis-1/5 md:mx-10 md:my-10 flex flex-col items-center">
                            <img className="w-[10VH] border border-black rounded-full" src={sanitazer} alt="sanitazer"/>
                            <div className="text-sm">Using a hand sanitizer</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-2 border-t-2 border-primary500">
                <div className="text-sm">Made With 
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline mx-1" viewBox="0 0 20 20" fill="red">
                        <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
                    </svg> 
                by Frista F Kresna - 2022</div>
                <div className="text-sm">Photo by 4klikphoto 
                    <a target="_blank"  rel="noreferrer" href="https://www.instagram.com/4klikphoto/">
                        <img className="inline" alt="insta" src={instagram} width={25} />
                    </a></div>
            </div>
        </div>
    )
}

export default Footer