import { useState } from "react"
import Countdown from "../countdown"

const WeddingInfo = () => {
    const[liveStream, setLiveStream] = useState(false)

    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get('type');

    return(
        <div>
            <div className="text-3xl font-bold mb-1 mt-10 md:text-4xl">Wedding Information</div>
            <div className="text-xl font-bold mb-5">Saturday, 05 November 2022</div>
            <div className="md:px-36">
                <div className="flex flex-col md:flex-row justify-around border p-5 md:p-10 bg-primary300 md:rounded-lg mb-5">
                    <div>
                        <div className="text-2xl font-bold mb-1 md:text-3xl">Holy Matrimony</div>
                        <p className="text-lg mb-2">at 10:00</p>
                        <p className="text-lg">Gereja Isa Almasih</p>
                        <p className="text-lg mb-5">Taman Kopo Indah I C33, Bandung</p>
                        <a                                                                                                                              
                        className="py-1.5 px-8 border text-primary300 bg-primary800 border-primary700 font-semibold rounded-md inline-block hover:bg-primary300 hover:text-primary900 hover:border-primary900"                                                                                                                                
                        target="_blank"  rel="noreferrer" href="https://www.google.com/maps/place/Gereja+Isa+Almasih+Taman+Kopo+Indah/@-6.9662988,107.5693322,15z/data=!4m5!3m4!1s0x0:0xf3ce03fc8c5723fb!8m2!3d-6.9662987!4d107.5693305?hl=en">Location</a>
                    </div>
                    {
                        type === "0" &&
                        <>
                            <div className="my-8 md:my-0">
                                <div className="border-b-2 md:border-l-2 h-full border-black"></div>
                            </div>
                            <div>
                                <div className="text-2xl font-bold mb-1 md:text-3xl">Reception</div>
                                <p className="text-lg mb-2">at 17:00</p>
                                <p className="text-lg">Grand Eastern Restaurant & Ballroom</p>
                                <p className="text-lg mb-5">Jl. Pasir Kaliki No.18, Kota Bandung</p>
                                <a 
                                className="py-1.5 px-8 border text-primary300 bg-primary800 border-primary700 font-semibold rounded-md inline-block hover:bg-primary300 hover:text-primary900 hover:border-primary900"                                                                                                                                
                                target="_blank"  rel="noreferrer" href="https://www.google.com/maps/place/Grand+Eastern+Restaurant+%26+Ballroom/@-6.9152569,107.5983137,15z/data=!4m5!3m4!1s0x0:0x77eeb8a9aca07e7!8m2!3d-6.9152569!4d107.5983137">Location</a>
                            </div>
                        </>
                    }
                </div>
            </div>
            <Countdown/>
            <div className="my-5">
                <button 
                    className="mb-5 py-1.5 px-8 border text-primary300 bg-primary800 border-primary700 font-semibold rounded-md inline-block hover:bg-primary300 hover:text-primary900 hover:border-primary900"                                                                                                                                
                    onClick={()=>setLiveStream(!liveStream)}>See Live Stream</button>
                {
                    liveStream &&
                    <iframe className="h-[30vh] w-full md:h-[60vh] md:w-1/2 m-auto" title="livestream" src='https://www.youtube.com/embed/paTHXFNPybc?controls=1'></iframe>
                }
            </div>
        </div>
    )
}

export default WeddingInfo