import qrcode from '../../public/assets/qrcode.jpeg'

const WeddingGift = () => {
    return(
        <div className='my-5'>
            <div>
                <img className='m-auto' src={qrcode} width={150} alt="qrcode" />
            </div>
            <div>BCA - 5140476228</div>
            <div>Septevanny Gunawan</div>
        </div>
    )
}

export default WeddingGift